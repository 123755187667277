<template>
  <v-timeline-item
    color="leadActivity"
    icon="mdi-radar"
    fill-dot
  >
    <v-row
      no-gutters
      align="center"
      class="pr-12 mt-1 mb-2"
    >
      <v-col md="8">
        <div class="subtitle-1">
          Hat das Eigentums-Radar aktiviert
        </div>
        <div class="primary--text text--small">
          <router-link :to="{ name: 'lead-property-tracker' }">
            Analyse ansehen und Kunden neugierig machen
          </router-link>
        </div>
      </v-col>
      <v-col
        class="pr-8 text-right grey--text text--small"
        md="4"
      >
        {{ formatDate(data.createdAt) }}
      </v-col>
    </v-row>
  </v-timeline-item>
</template>
<script>
export default {
  props: {
    data: { type: Object, default: null }
  }
}
</script>

<style scoped>
.text--small {
  font-size: 15px;
}
</style>
