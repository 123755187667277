<template>
  <v-card
    class="mt-4 pa-4"
    outlined
  >
    <h4 class="mb-1 subtitle-2">
      Status
    </h4>
    <v-select
      v-model="selectedStatus"
      :items="statusList"
      :item-disabled="disabledStatus"
      :menu-props="{ maxHeight: 340 }"
      hide-details
      outlined
      dense
      @change="handleStatusChange"
    />

    <div v-if="isExpert">
      <h4 class="mt-4 mb-1 subtitle-2">
        Verantwortlicher Mitarbeiter
      </h4>
      <v-select
        v-model="selectedUserId"
        :items="usersForSelect"
        placeholder="keiner zugewiesen"
        hide-details
        outlined
        dense
        clearable
        @change="handleAssignedUserChange"
      />
    </div>
  </v-card>
</template>

<script>
import trackingEvents from '@/lib/trackingEvents'
import { statusForSelect } from '@/lib/leadStatus'
import { isExpertUser } from '@/lib/productTypes'
import { sortByString } from '@/lib/sort-functions'
import LEAD from './queries/Lead.gql'
import USERS from '../queries/Users.gql'
import ASSIGN_USER from './queries/AssignUser.gql'
import UNASSIGN_USER from './queries/UnassignUser.gql'
import UPDATE_LEAD_STATUS from './queries/UpdateLeadStatus.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    status: {
      type: String,
      default: 'new'
    },
    leadId: {
      type: String,
      default: null
    },
    assignedUserId: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      selectedStatus: this.status,
      selectedUserId: this.assignedUserId,
      statusList: statusForSelect
    }
  },

  computed: {
    usersForSelect () {
      return (this.users ?? [])
        .map(user => ({
          value: user.id,
          text: `${user.firstname} ${user.lastname}`
        }))
        .sort(sortByString('text'))
    },
    isExpert () {
      return isExpertUser(this.$auth.user)
    }
  },

  apollo: {
    users: {
      query: USERS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: data => data.company?.users
    }
  },
  methods: {
    disabledStatus ({ value }) {
      return this.status !== 'created' && value === 'created'
    },
    async handleStatusChange () {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_LEAD_STATUS,
          variables: {
            leadId: this.leadId,
            status: this.selectedStatus
          },
          refetchQueries: [
            { query: LEAD, variables: { id: this.leadId } }
          ]
        })

        this.showSuccess('Status aktualisiert')

        this.triggerAnimation(this.selectedStatus)

        window.Appcues.track(trackingEvents.LEAD_STATUS_UPDATED, {
          newStatus: this.selectedStatus
        })
      } catch (e) {
        this.selectedStatus = this.status
        this.showError('Status konnte nicht aktualisiert werden')
      }
    },

    async handleAssignedUserChange () {
      try {
        if (this.selectedUserId) {
          await this.assignUser()
        } else {
          await this.unassignUser()
        }
        this.showSuccess('Verantwortlicher Mitarbeiter erfolgreich geändert')
      } catch (e) {
        this.selectedUserId = this.assignedUserId
        this.showError('Fehler beim Ändern des verantwortlichen Mitarbeiters')
      }
    },

    assignUser () {
      return this.$apollo.mutate({
        mutation: ASSIGN_USER,
        variables: {
          leadId: this.leadId,
          userId: this.selectedUserId
        }
      })
    },

    unassignUser () {
      return this.$apollo.mutate({
        mutation: UNASSIGN_USER,
        variables: {
          leadId: this.leadId
        }
      })
    },

    showSuccess (message) {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: message })
    },

    showError (message) {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: message })
    },

    triggerAnimation (selectedStatus) {
      const showAnimationStates = ['reached', 'orderReceived']
      if (showAnimationStates.includes(selectedStatus)) {
        bus.$emit(eventNames.TRIGGER_ANIMATION)
      }
    }
  }
}

</script>
