<template>
  <v-card
    v-if="hasProperty"
    outlined
  >
    <v-card-title class="pt-12 pb-2 pl-4 blue">
      <span class="title white--text">
        Eigentums-Radar
      </span>
    </v-card-title>
    <v-card-text class="pt-2">
      <template v-if="propertyTrackerDate">
        <p>Der Kontakt hat das Eigentums-Radar am {{ formatDate(propertyTrackerDate, 'dateShort') }} aktiviert.</p>
        <router-link :to="{ name: 'lead-property-tracker' }">
          Analyse ansehen und Kunden neugierig machen
        </router-link>
      </template>
      <template v-else-if="invitationDate">
        <p>Die Einladung wurde am {{ formatDate(invitationDate, 'dateShort') }} an den Kontakt versendet.</p>
      </template>
      <template v-else-if="allowInvitation">
        <p>
          Laden Sie diesen Kontakt zum Eigentums-Radar ein.
          Damit startet die regelmäßige datengetriebene Ansprache mit spezifischen Informationen rund um die Immobilie des Kontaktes.
        </p>
        <p>Sobald der Kontakt eine Analyse erstellt hat, sehen Sie diese Analyse hier in den Kontaktdetails.</p>
      </template>
    </v-card-text>
    <v-card-actions
      v-if="!propertyTrackerDate"
      class="justify-end d-flex"
    >
      <v-btn
        class="mb-3 mr-3 primary"
        small
        :disabled="!allowInvitation"
        @click="submit"
      >
        Jetzt einladen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import bus, { eventNames } from '@/lib/eventBus'
import TRIGGER_PROPERTY_TRACKER_INVITATION from '../queries/TriggerPropertyTrackerInvitation.gql'
import PERMISSIONS from '@/queries/Permissions.gql'

export default {
  props: {
    lead: { type: Object, default: null },
    onRefetch: { type: Function, default: () => {} }
  },
  data () {
    return {
      dialog: false,

      permissions: {
        leadImport: {
          hasPermission: false
        }
      }
    }
  },
  apollo: {
    permissions: {
      query: PERMISSIONS,
      update: data => data
    }
  },
  computed: {
    hasProperty () {
      return Boolean(this.permissions.leadImport.hasPermission && this.lead?.purchaseProperty)
    },
    propertyTrackerDate () {
      if (this.lead.purchaseProperty && this.lead.purchaseProperty.dossier) {
        return this.lead.purchaseProperty.dossier.createdAt
      }
      return false
    },
    allowInvitation () {
      return Boolean(
        this.lead.purchaseProperty &&
        !this.lead.purchaseProperty.dossier &&
        !this.lead.purchaseProperty.landingpageAccessCode
      )
    },
    invitationDate () {
      if (this.lead.purchaseProperty?.landingpageAccessCode) {
        return this.lead.purchaseProperty.updatedAt
      }
      return false
    }
  },
  methods: {
    async submit () {
      try {
        bus.$emit(eventNames.SHOW_ANIMATION)
        await this.$apollo.mutate({
          mutation: TRIGGER_PROPERTY_TRACKER_INVITATION,
          variables: {
            leadIds: [this.lead.id]
          }
        })
        await this.onRefetch()
        this.dialog = false
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einladung versendet' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Versand der Einladung' })
      }
    }
  }
}
</script>
