<template>
  <Valuation
    v-if="valuation"
    :valuation="valuation"
    :lead-data="valuation.lead"
    :user="valuation.user"
  />
</template>

<script>
import GET_VALUATION from '@/queries/GetValuation.gql'
import Valuation from './Valuation.vue'

export default {
  components: { Valuation },
  props: {
    data: { type: Object, default: null }
  },
  apollo: {
    valuation: {
      query: GET_VALUATION,
      variables () {
        return {
          valuationId: this.data.details.valuationId
        }
      }
    }
  }
}
</script>
