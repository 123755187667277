<template>
  <v-timeline-item
    color="userActivity"
    icon="mdi-email-outline"
    fill-dot
  >
    <v-row
      no-gutters
      align="center"
      class="pr-12 mb-2 mt-n2 d-flex justify-center"
      :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
    >
      <v-col md="8">
        <div class="subtitle-1">
          Per E-Mail zum Akquise-Booster "{{ topic }}" eingeladen
        </div>
        <div class="grey--text text--small">
          von {{ userName }}
        </div>
      </v-col>
      <v-col
        class="text-right grey--text text--small pr-8"
        :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
        md="4"
      >
        {{ formatDate(data.createdAt) }}
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null }
  },
  computed: {
    topic () {
      return this.data.details.invitationTopic
    },
    userName () {
      return `${this.data.user.firstname} ${this.data.user.lastname}`
    }
  }
}
</script>

<style scoped>
.text--small {
  font-size: 15px;
}
</style>
