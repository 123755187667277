import bus, { eventNames } from '@/lib/eventBus'
import INVITE_LEADS_TO_LANDINGPAGE from '@/modules/leads/queries/InviteLeadsToLandingpage.gql'
import LANDINGPAGE_TEMPLATES_FOR_COMPANY from '@/queries/LandingpageTemplatesForCompany.gql'
import LANDINGPAGES from '@/modules/leads/Landingpages.gql'

// These are non-relevant topics according to Axel and should't be available for invitations
const NON_RELEVANT_TOPICS = [
  'eigentums-radar',
  'was-gehoert-in-ein-expose',
  'unterlagen-fuer-den-verkauf',
  'aufbereitung-der-immobilie',
  'energieausweis',
  'immobilie-geerbt-checkliste',
  'bewirtschaftung-einer-immobilie-checkliste'
]

export default {
  props: {
    lead: { type: Object, default: null },
    onRefetch: { type: Function, default: () => { } }
  },
  data () {
    return {
      landingpageSlug: ''
    }
  },
  apollo: {
    landingpageTemplates: {
      query: LANDINGPAGE_TEMPLATES_FOR_COMPANY,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            onlyDefault: true
          }
        }
      }
    },
    company: {
      query: LANDINGPAGES,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },
  computed: {
    landingpages () {
      return this.company.landingpages.filter(({ slug }) =>
        this.$features.feature(`lp-${slug}`).isActive && !NON_RELEVANT_TOPICS.includes(slug)
      )
    },
    landingpageSlugs () {
      const filteredLandingpageTemplates = this.landingpageTemplates.filter(({ slug }) => !slug.startsWith('ms-'))
      const disabledLandingpageSlugs = filteredLandingpageTemplates.map(lpTemplate => ({ ...lpTemplate, disabled: true }))
      const all = [...this.landingpages, ...disabledLandingpageSlugs]
      const tools = all.filter(lp => lp.type !== 'GUIDE' && lp.type !== 'CHECKLIST')
      const guides = all.filter(lp => lp.type === 'GUIDE')
      const checklists = all.filter(lp => lp.type === 'CHECKLIST')

      const slugs = [{ header: 'Tools' }, ...tools]
      if (guides.length > 0) { slugs.push({ divider: true }, { header: 'Ratgeber' }, ...guides) }
      if (checklists.length > 0) { slugs.push({ divider: true }, { header: 'Checklisten' }, ...checklists) }

      return slugs
    },
    leadName () {
      return `${this.lead.firstname} ${this.lead.lastname}` || this.lead.email
    }
  },
  methods: {
    async submit () {
      try {
        await this.$apollo.mutate({
          mutation: INVITE_LEADS_TO_LANDINGPAGE,
          variables: {
            input: {
              leadIds: [this.lead.id],
              landingpageSlug: this.landingpageSlug
            }
          }
        })
        await this.onRefetch()
        this.landingpageSlug = ''
        bus.$emit(eventNames.SHOW_ANIMATION)
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einladung versendet' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Versand der Einladung' })
      }
    }
  }
}
