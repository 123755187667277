<template>
  <v-card
    v-if="lead"
    outlined
  >
    <div class="pt-12 pb-2 pl-4 primary">
      <span class="title white--text">
        <template v-if="lead.firstname || lead.lastname">
          {{ lead.firstname }} {{ lead.lastname }}
        </template>
        <template v-else>
          {{ lead.email }}
        </template>
      </span>
    </div>
    <v-list class="base-info">
      <v-list-item>
        <v-list-item-icon class="my-2 mr-5">
          <v-icon>mdi-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a
              v-if="lead.formattedPhone"
              :href="`tel:${lead.formattedPhone}`"
            >
              {{ lead.formattedPhone }}
            </a>
            <template v-else>
              –––
            </template>
          </v-list-item-title>
          <v-list-item-subtitle>
            Telefonnummer
          </v-list-item-subtitle>
          <phone-list :phone-numbers="lead.phones" />
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon class="my-2 mr-5">
          <v-icon
            v-if="isDisplayedNumberVerified"
            color="success"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            v-else
            color="orange"
          >
            mdi-clock
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="break-line">
            <template v-if="isDisplayedNumberVerified">
              Telefonnummer bestätigt
            </template>
            <template v-else>
              Telefonnummer Bestätigung ausstehend
            </template>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon class="my-2 mr-5">
          <v-icon>mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="break-line">
            <a :href="`mailto:${lead.email}`">{{ lead.email }}</a>
          </v-list-item-title>
          <v-list-item-subtitle>
            E-Mail
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon class="my-2 mr-5">
          <v-icon
            v-if="lead.confirmedAt"
            color="success"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            v-else
            color="orange"
          >
            mdi-clock
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="break-line">
            <template v-if="lead.confirmedAt">
              E-Mail bestätigt
            </template>
            <template v-else>
              E-Mail Bestätigung ausstehend
            </template>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <Feature :feature-slug="featureNames.HOPPERMATION">
        <Channel
          v-for="channel in lead.channels"
          :key="channel.id"
          :lead-id="lead.id"
          :channel="channel"
        />
      </Feature>
    </v-list>

    <!-- Edit the Lead Data -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      persistent
    >
      <template #activator="{ on }">
        <div class="justify-end mt-3 d-flex">
          <v-btn
            class="mx-5 mb-5 primary white--text"
            small
            v-on="on"
          >
            <v-icon
              class="mr-2"
              small
            >
              mdi-pencil
            </v-icon>
            Kontakt bearbeiten
          </v-btn>
        </div>
      </template>
      <template v-slot:default="dialog">
        <v-form
          ref="editLeadForm"
          v-model="isFormValid"
          @submit.prevent="submit(dialog)"
          @change="error = ''"
        >
          <v-card>
            <v-card-title
              class="pa-0"
            >
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Kontakt Informationen bearbeiten</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="cancelDialog(dialog)"
                  >
                    <v-icon large>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="leadData.firstname"
                      label="Vorname"
                      outlined
                      autofocus
                      @focus="validateEditLeadData"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="leadData.lastname"
                      label="Nachname"
                      outlined
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <PhoneField
                      v-model="leadData.phone"
                      :rules="lead.phone ? [rules.required] : []"
                      :error-message="errors.phone"
                      validate-on-blur
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>
                      Wenn Sie diese Informationen manuell bearbeiten, wird der Datensatz geschützt.
                      Falls der Kontakt weitere Aktivitäten durchführt und dabei z.B. eine andere Telefonnummer angibt, werden Ihre Korrekturen nicht überschrieben.
                      Nur noch Sie können die Kontaktinformationen ändern.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="cancelDialog(dialog)"
              >
                Abbrechen
              </v-btn>
              <v-btn
                text
                color="primary"
                type="submit"
              >
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <!-- End Edit Lead -->
  </v-card>
</template>

<script>
import { required } from '@/lib/validation'
import UPDATE_LEAD from './queries/UpdateLeadPersonalData.gql'
import PhoneList from './PhoneList.vue'
import { COUNTRIES } from '../../../components/forms/labels'
import bus, { eventNames } from '@/lib/eventBus'
import PhoneField from '@/components/PhoneField.vue'
import featureMixin from '@/mixins/feature'
import { Feature } from 'feature-config'
import Channel from './Channel.vue'

export default {
  components: { PhoneList, PhoneField, Feature, Channel },
  mixins: [featureMixin],
  props: {
    lead: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      leadData: { ...this.lead },
      errors: { phone: '' },
      isFormValid: false
    }
  },
  computed: {
    isDisplayedNumberVerified () {
      if (this.lead.formattedPhone) {
        for (const phone of this.lead.phones) {
          if (phone.phone === this.lead.formattedPhone && phone.verifiedAt) {
            return true
          }
        }
      }
      return false
    }
  },
  created () {
    this.rules = { required }
  },

  methods: {
    validateEditLeadData () {
      this.$refs.editLeadForm.validate()
    },
    cancelDialog (dialog) {
      dialog.value = false
      this.leadData = { ...this.lead }
      this.errors = { phone: '' }
    },
    async submit (dialog) {
      this.errors = { phone: '' }

      if (this.$refs.editLeadForm.validate()) {
        const { id, firstname, lastname, phone } = this.leadData

        try {
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_LEAD,
            variables: {
              input: { id, firstname, lastname, phone, companyId: this.$auth.user.companyId }
            }
          })

          if (data.lead) {
            dialog.value = false
            bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Kontakt aktualisiert' })
            this.$emit('lead-updated')
          }
        } catch (e) {
          if (e.graphQLErrors?.[0]?.message === 'INVALID_PHONE_ERROR') {
            this.errors.phone = 'Invalide Telefonnummer'
          }
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
        }
      }
    },
    getCountryByCode (code) {
      return COUNTRIES.find(({ value }) => value.toUpperCase() === code?.toUpperCase())?.text || code
    }
  }
}
</script>

<style scoped>
.break-line {
  white-space: normal;
}

.base-info .v-list-item {
  min-height: 32px;
}
</style>
