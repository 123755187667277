<template>
  <v-timeline-item
    v-if="conversion"
    color="leadActivity"
    icon="mdi-file-document-edit"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col md="8">
              <div class="mb-2 subtitle-1">
                Hat einen Leibrenten-Check durchgeführt
              </div>
              <div
                v-if="conversion"
                class="grey--text"
              >
                {{ propertyTypeLabel }}
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="conversion">
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Angegebener Kontakt:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ name }}

                      <div class="grey--text text--darken-3 body-2">
                        {{ phone }}
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Geschlecht:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ gender }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Geburtsjahr:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ yearOfBirth }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Bevorzugte Rückrufzeit:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ callbackTimeOfDay }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Quelle:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ source }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Objektart:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ propertyTypeLabel }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Postleitzahl:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ property.zip }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Geschätzter Wert:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ formatPrice(property.value) }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Wohnfläche:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ property.livingArea }} {{ formatSpaceUnit() }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Baujahr:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ property.buildingYear }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Anzahl Garagen:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ property.garageAmount }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Selbstbewohnt:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ property.selfOccupied ? 'Ja':'Nein' }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Auszahlungsweise:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ payoutType }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import spaceUnitMixin from '@/mixins/spaceUnit'
import { propertyTypes } from '@/lib/propertyTypes'
import CONVERSION from '../queries/Conversion.gql'
import { readableGender } from '@/lib/gender'
import { readablePayoutType } from '@/lib/payoutType'
import { conversionSource } from '@/lib/conversionSource'
import { timeToLabelMap } from '@/lib/callbackTimeOfDay'

export default {
  mixins: [currencyMixin, spaceUnitMixin],
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      conversion: {
        personalData: {},
        lifeAnnuity: {}
      }
    }
  },
  computed: {
    gender () {
      return readableGender[this.conversion.personalData.gender]
    },
    yearOfBirth () {
      return this.conversion.personalData.yearOfBirth
    },
    payoutType () {
      return readablePayoutType[this.conversion.lifeAnnuity?.payoutType]
    },
    property () {
      return this.conversion?.lifeAnnuity?.property || {}
    },
    propertyTypeLabel () {
      return propertyTypes[this.property?.type?.toUpperCase()]
    },
    name () {
      return `${this.conversion.personalData.firstname} ${this.conversion.personalData.lastname}`
    },
    phone () {
      return this.conversion.personalData.formattedPhone
    },
    callbackTimeOfDay () {
      if (!this.conversion) return
      return this.conversion?.lifeAnnuity?.callbackTimeOfDay
        .map(time => timeToLabelMap[time])
        .join(', ')
    },
    source () {
      if (!this.conversion) return
      return this.conversion.source || conversionSource[this.conversion.origin] || ''
    }
  },
  apollo: {
    conversion: {
      query: CONVERSION,
      variables () {
        return {
          conversionId: this.data.details[this.data.type].conversionId
        }
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
