<template>
  <v-timeline-item
    color="userActivity"
    icon="mdi-lead-pencil"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col
              md="8"
            >
              <div class="mb-2 subtitle-1 text-truncate">
                <span class="text-wrap">{{ data.text }}</span>
              </div>
              <div class="grey--text text--small">
                Kommentar von {{ data.userName }}
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text text--small"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col md="12">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-textarea
                      class="mr-6"
                      outlined
                      disabled
                      hide-details
                      auto-grow
                      :value="data.text"
                    />

                    <v-dialog
                      v-model="dialog"
                      width="350"
                    >
                      <template v-slot:activator="{ on }">
                        <div class="justify-end pt-1 pr-8 d-flex">
                          <span
                            class="error--text body-2"
                            style="cursor: pointer; text-decoration: underline;"
                            v-on="on"
                          >
                            Löschen
                          </span>
                        </div>
                      </template>

                      <v-card>
                        <v-card-title
                          class="pa-0"
                        >
                          <v-toolbar
                            color="primary"
                            dark
                            flat
                          >
                            <v-toolbar-title>Wirklich löschen?</v-toolbar-title>
                            <v-spacer />
                            <v-toolbar-items>
                              <v-btn
                                icon
                                dark
                                @click="dialog = false"
                              >
                                <v-icon large>
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-toolbar-items>
                          </v-toolbar>
                        </v-card-title>

                        <v-card-text class="mt-4">
                          <span>
                            Wollen Sie diesen Kommentar wirklich löschen?
                          </span>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer />

                          <v-btn
                            text
                            @click="dialog = false"
                          >
                            Abbrechen
                          </v-btn>

                          <v-btn
                            color="error"
                            text
                            @click="deleteCommentary"
                          >
                            Bestätigen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import LEAD from '../queries/Lead.gql'
import DELETE_COMMENTARY from '../queries/DeleteCommentary.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    data: { type: Object, default: null },
    leadId: { type: String, default: null }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    async deleteCommentary () {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: DELETE_COMMENTARY,
          variables: {
            leadId: this.leadId,
            commentaryId: this.data.id
          },
          refetchQueries: [
            { query: LEAD, variables: { id: this.leadId } }
          ]
        })

        if (data.response) this.dialog = false

        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Kommentar gelöscht' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Löschen' })
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
