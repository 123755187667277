export const COUNTRIES = [
  { value: 'DE', text: 'Deutschland' },
  { value: 'AT', text: 'Österreich' },
  { value: 'CH', text: 'Schweiz' }
]

export const PAYMENT_METHODS = [
  { value: 'DIRECT_DEBIT', name: 'Monatlicher Bankeinzug' },
  { value: 'PREPAYMENT', name: 'Vorauszahlung per Rechnung' }
]

export const PAYMENT_METHODS_CH = [
  { value: 'INVOICE', name: 'Monatliche Rechnung' },
  { value: 'PREPAYMENT', name: 'Vorauszahlung per Rechnung' }
]

export const SALUTATIONS = [
  { value: 'Herr', text: 'Herr' },
  { value: 'Frau', text: 'Frau' }
]

export const LANDNINGPAGE_TEASER = {
  'wohnen-im-alter': {
    subtitle: 'Alles rund um das große Thema „Wohnen im Alter“.',
    text: 'Wenn die Treppen zu steil, der Garten zu groß und einige Zimmer zu leer sind, dann wird es für ältere Menschen Zeit, die Wohnsituation zu überdenken. Hier ist Rat gefragt, der sehr häufig aktiv recherchiert wird. Bringen Sie Ihre Dienstleistung ins Gespräch, indem Sie den Dialog mit diesem hochwertigen Ratgeber beginnen. '
  },
  'immobilie-in-der-scheidung': {
    subtitle: 'Zielführende Wege im Umgang mit der gemeinsamen Immobilie.',
    text: 'Einer der häufigsten Gründe für den ungeplanten Immobilienverkauf ist die Scheidung der Bewohner. Und zugleich ist dies der Anlass für die Wahl eines Immobilienmaklers. Denn wenn man sich nicht mehr vertraut oder gar nicht mehr miteinander redet, dann braucht es einen Vermittler. Zeigen Sie mit diesem Ratgeber, dass Sie sich auskennen.'
  },
  'immobilie-geerbt': {
    subtitle: 'Was im Erbfall wichtig ist und welche Möglichkeiten Eigentümer haben.',
    text: `Diese Tipps rund um das Erben und Vererben einer Immobilie sind äußerst beliebt bei Immobilieneigentümern. Hier möchte niemand Fehler machen und keinesfalls Geld verschenken. Gerade Erbengemeinschaften sind oft auf der Suche nach Informationen - und dem richtigen Makler, der zwischen den Interessen vermitteln kann und dabei das Beste für alle rausholt.
    Zeigen Sie Ihre Erfahrung und Ihre Expertise mit Ihrem eigenen Ratgeber zum Thema “Immobilie geerbt”.`
  },
  'immobilie-geerbt-ratgeber': {
    subtitle: 'Was im Erbfall wichtig ist und welche Möglichkeiten Eigentümer haben.',
    text: `Diese Tipps rund um das Erben und Vererben einer Immobilie sind äußerst beliebt bei Immobilieneigentümern. Hier möchte niemand Fehler machen und keinesfalls Geld verschenken. Gerade Erbengemeinschaften sind oft auf der Suche nach Informationen - und dem richtigen Makler, der zwischen den Interessen vermitteln kann und dabei das Beste für alle rausholt.
    Zeigen Sie Ihre Erfahrung und Ihre Expertise mit Ihrem eigenen Ratgeber zum Thema “Immobilie geerbt”.`
  },
  'immobilien-fakten-und-begriffe': {
    subtitle: 'Hier werden alle notwendigen Begriffe erklärt, um dieselbe Sprache wie ein Immobilienmakler zu sprechen.',
    text: 'Die Immobilienbranche fasziniert viele Menschen. Insbesondere Eigentümer und Investoren wollen mehr erfahren. Da sind Sie bei Ihnen als Experte genau richtig. Mit diesem Ratgeber zeigen Sie, wie tief Sie im Thema sind und glänzen mit umfassender Expertise.'
  },
  'privater-immobilienverkauf': {
    subtitle: 'Erfolgreich zum Top-Preis verkaufen.',
    text: 'Makler? Wozu brauche ich den eigentlich? Und tatsächlich versuchen immer mehr Eigentümer sich selbst am Verkauf Ihrer Immobilie. Geben Sie doch bereitwillig Tipps aus Ihrem Makleralltag, dann wird vielen Neulingen die Komplexität des Themas schnell bewusst. Dieser sehr stark nachgefragte Ratgeber hat schon viele Privatverkäufer zum Umdenken und damit zum Makler gebracht.'
  },
  'richtiger-immobilienpreis': {
    subtitle: 'Zeigen Sie, wie man mit der richtigen Strategie zum optimalen Angebotspreis kommt.',
    text: 'Letztendlich ist vieles eine Frage des Preises. Und mit den Preisen in Ihrer Farm kennen Sie sich als Makler perfekt aus! Sie wissen, welchen Effekt zu hohe oder zu niedrige Startpreise haben können. Zeigen Sie sich mit diesem beliebten Ratgeber als kompetenter Partner für den ergebnisorientierten Verkauf einer Immobilie.'
  },
  'sanierung-einer-immobilie': {
    subtitle: 'So verschönert man ein Zuhause und steigert den Wert.',
    text: 'Sanieren oder verkaufen? Diese Frage stellen sich viele Eigentümer. Sie als Makler wissen Rat, denn Sie kennen den Markt, die Möglichkeiten und die Preise. Mit diesem Ratgeber starten Sie sich ein Gespräch mit Menschen, die Sie sonst vermutlich gar nicht an “ich rede mal mit einem Immobilienmakler” gedacht hätten.'
  },
  'bewirtschaftung-einer-immobilie-ratgeber': {
    text: 'Brauche ich wirklich einen Makler, um eine Immobilie zu bewirtschaften? Mit dieser Frage beschäftigen sich einige Eigentümer. Unterstützen Sie mit Ihrem Rat, zeigen Sie Fehler und Fallen und überzeugen Sie auf diese Weise davon, Sie mit der Bewirtschaftung zu beauftragen.'
  },
  leibrente: {
    subtitle: 'Alles über das Trend-Thema Verrentung.',
    text: 'Verkaufen und wohnen bleiben? Das geht. In der eigenen Immobilie wohnen zu bleiben und zugleich mehr finanzielle Freiheit für den Alltag zu genießen, darf tatsächlich als ein Trendthema gelten. Dieser Ratgeber wendet sich direkt an eine ältere Klientel auf der Suche nach Informationen. Für Sie als Makler: ein super spannender Kontakt.'
  },
  immobilienfinanzierung: {
    subtitle: 'Das komplette Wissen rund um die Finanzierung von Immobilien.',
    text: 'Vor dem Kauf steht die Finanzierung. Bereits an dieser Stelle sollten Sie als Immobilienmakler als kompetenter Berater auftreten. Denn Sie kennen sich aus und haben die richtigen Kontakte. Zugleich steht die Recherche nach der Finanzierung einer Immobilie oft auch im Zusammenhang mit einer Ersatzbeschaffung.'
  },
  'energetische-sanierung': {
    subtitle: 'Das müssen Eigentümer jetzt wissen.',
    text: 'Die Energiewende ist eines der wichtigsten Themen der Gegenwart. Welchen Beitrag können (und müssen) Privathaushalte leisten? Immobilieneigentümer sind auf der Suche nach Antworten auf dringende Fragen - Sie als Makler können sich hier als Experte in Gespräch bringen. Dieser Ratgeber klärt Ihre Kunden auf und sorgt zugleich für Neukontakte in Ihrem Expertengebiet.'
  }
}

export const EXAMPLE_COMPANIES = {
  ATV1: 'mm-atv1',
  CHV1: 'mm-chv1',
  CHV2: 'mm-chv2',
  VOLKSBANK: 'mm-volksbank',
  REMAX: 'mm-remax',
  DV1: 'mm-dv1',
  DV2: 'mm-dv2',
  DV3: 'mm-dv3',
  DV4: 'mm-dv4'
}
