<template>
  <div>
    <SingleCircle
      class="banner-design-elements"
      width="50px"
      style="right: 120px; top:115px; opacity: 0.5"
      fill
    />
    <Circles
      class="banner-design-elements"
      width="300px"
      style="right: -5px; top:-10px; opacity: 0.4"
      stroke
    />
  </div>
</template>

<script>
import Circles from '@/components/design-elements/circles.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'

export default {
  components: {
    Circles,
    SingleCircle
  }
}
</script>
