import Accessibility from '@/lib/enums/propertyRequest/Accessibility'
import AvailableStartDate from '@/lib/enums/propertyRequest/AvailableStartDate'
import Financing from '@/lib/enums/propertyRequest/Financing'
import LivingSituation from '@/lib/enums/propertyRequest/LivingSituation'
import OutdoorArea from '@/lib/enums/propertyRequest/OutdoorArea'
import ResidentialArea from '@/lib/enums/propertyRequest/ResidentialArea'
import Surroundings from '@/lib/enums/propertyRequest/Surroundings'

export const AccessibilityLabels = {
  [Accessibility.REQUIRED]: 'Benötigt',
  [Accessibility.OPTIONAL]: 'Nicht zwingend benötigt',
  [Accessibility.NOT_REQUIRED]: 'Nicht benötigt'
}
export const AvailableStartDateLabels = {
  [AvailableStartDate.EARLIEST]: 'zum nächstmöglichen Zeitpunkt',
  [AvailableStartDate.LESS_THAN_6_MONTH]: '< 6 Monate',
  [AvailableStartDate.MORE_THAN_6_MONTH]: '>  6 Monate',
  [AvailableStartDate.DEPENDING_ON_OFFER]: 'hängt vom Angebot ab',
  [AvailableStartDate.DEPENDING_ON_SALE]: 'gekoppelt an Verkauf'
}
export const FinancingLabels = {
  [Financing.CLEAR]: 'geklärt',
  [Financing.CLARIFICATION]: 'in Klärung',
  [Financing.UNCLEAR]: 'ungeklärt',
  [Financing.CONSULTING_NEEDED]: 'Beratungsbedarf'
}
export const LivingSituationLabels = {
  [LivingSituation.RENT]: 'zur Miete',
  [LivingSituation.RENT_FREE]: 'mietfrei',
  [LivingSituation.OWNER]: 'im Eigentum'
}
export const OutdoorAreaLabels = {
  [OutdoorArea.BALCONY]: 'Balkon',
  [OutdoorArea.TERRACE]: 'Terrasse',
  [OutdoorArea.TERRACE_WITH_GARDEN]: 'Terrasse mit Garten',
  [OutdoorArea.GARDEN]: 'nur Garten'
}
export const ResidentialAreaLabels = {
  [ResidentialArea.QUIET]: 'möglichst ruhig',
  [ResidentialArea.URBAN]: 'möglichst urban',
  [ResidentialArea.RURAL]: 'möglichst im Grünen'
}
export const SurroundingsLabels = {
  [Surroundings.HEALTHCARE]: 'Arzt / Krankenhaus',
  [Surroundings.EDUCATION]: 'Schule / Kindergarten',
  [Surroundings.SHOPPING]: 'Einkaufsmöglichkeiten',
  [Surroundings.GASTRONOMY]: 'Gastronomie',
  [Surroundings.PUBLIC_TRANSPORT]: 'Öffentlicher Verkehr'
}
