<template>
  <v-container
    fluid
    class="h-full"
  >
    <leads />
  </v-container>
</template>

<script>
import Leads from '@/modules/leads'

export default {
  components: {
    Leads
  }
}
</script>
