export const topicToLabelMap = {
  PRICING: 'Preisfindung',
  INHERITANCE: 'Erbschaft',
  DIVORCE: 'Scheidung',
  SALE: 'Verkauf',
  RENT: 'Vermietung',
  BUYING_INTEREST: 'Kaufinteresse',
  CREDIT: 'Finanzierung',
  REPLACEMENT: 'Ersatzbeschaffung',
  LIFE_ANNUITY: 'Leibrente',
  PARTIAL_SALE: 'Teilverkauf',
  PROPERTY_REQUEST: 'Kaufgesuch',
  PROPERTY_TAX: 'Grundsteuer',
  OTHER: 'Sonstiges'
}
