<template>
  <v-timeline-item
    color="leadActivity"
    icon="mdi-phone"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="justify-center d-flex"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col md="8">
              <div class="mb-2 subtitle-1">
                Hat um einen Rückruf gebeten
              </div>
              <div class="grey--text">
                {{ callbackTimeOfDay }} zum Thema {{ topics }}
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Angegebener Kontakt:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ name }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ phone }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Quelle:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ source }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Bevorzugte Rückrufzeit:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ callbackTimeOfDay }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Thema:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      <span> {{ topics }}  </span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import CONVERSION from '../queries/Conversion.gql'

import { topicToLabelMap } from '@/lib/topics'
import { timeToLabelMap } from '@/lib/callbackTimeOfDay'
import { conversionSource } from '@/lib/conversionSource'

export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      conversion: null
    }
  },
  computed: {
    name () {
      if (!this.conversion) return
      return `${this.conversion.personalData.firstname} ${this.conversion.personalData.lastname}`
    },
    phone () {
      if (!this.conversion) return
      return this.conversion.personalData.formattedPhone
    },
    callbackTimeOfDay () {
      if (!this.conversion) return
      return this.conversion?.returnCall?.callbackTimeOfDay
        .map(time => timeToLabelMap[time])
        .join(', ')
    },
    topics () {
      if (!this.conversion) return
      // Provided OTHER topic
      if (this.conversion.returnCall.topics.includes('OTHER')) {
        if (this.conversion.returnCall.topicsOther.length > 0) {
          return this.conversion.returnCall.topicsOther
        }
        return 'Sonstiges'
      }
      // Provided one of the default topics
      return topicToLabelMap[this.conversion.returnCall.topics[0]]
    },
    source () {
      if (!this.conversion) return
      return this.conversion.source || conversionSource[this.conversion.origin] || ''
    }
  },
  apollo: {
    conversion: {
      query: CONVERSION,
      variables () {
        return {
          conversionId: this.data.details[this.data.type].conversionId
        }
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
