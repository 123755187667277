<template>
  <Valuation
    v-if="conversion"
    :valuation="conversion.valuation"
    :lead-data="conversion.personalData"
    :origin="conversion.origin"
    :source="conversion.source"
  />
</template>

<script>
import CONVERSION from '../queries/Conversion.gql'
import Valuation from './Valuation.vue'

export default {
  components: { Valuation },
  props: {
    data: { type: Object, default: null }
  },
  apollo: {
    conversion: {
      query: CONVERSION,
      variables () {
        return {
          conversionId: this.data.details[this.data.type].conversionId
        }
      }
    }
  }
}
</script>
