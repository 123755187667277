<template>
  <v-dialog
    v-model="dialog"
    width="550"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip, attrs }">
          <v-btn
            outlined
            v-on="on"
          >
            <v-icon
              v-bind="attrs"
              v-on="onTooltip"
            >
              mdi-email-off
            </v-icon>
          </v-btn>
        </template>
        <span>Automatische E-Mails stoppen</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Funnel wirklich stoppen?</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-4">
        <span>
          Wollen Sie den Funnel wirklich stoppen?
        </span>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          @click="dialog = false"
        >
          Abbrechen
        </v-btn>

        <v-btn
          color="error"
          text
          @click="unsubscribe"
        >
          Bestätigen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import STOP_CHANNEL_FOR_LEAD from './queries/StopChannelForLead.gql'
import LEAD from './queries/Lead.gql'

export default {
  props: {
    leadId: { type: String, required: true },
    channel: { type: Object, required: true }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    async unsubscribe () {
      try {
        await this.$apollo.mutate({
          mutation: STOP_CHANNEL_FOR_LEAD,
          variables: {
            input: {
              leadId: this.leadId,
              channelId: this.channel.channelId
            }
          },
          refetchQueries: [{ query: LEAD, variables: { id: this.leadId } }]
        })
        this.dialog = false
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Funnel wurde gestoppt' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Stoppen der Funnels' })
      }
    }
  }
}
</script>
