<template>
  <v-timeline-item
    color="userActivity"
    icon="mdi-information-variant"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col
              md="8"
            >
              <div class="mb-2 subtitle-1">
                Als manueller Kontakt hinterlegt
              </div>
              <div class="grey--text">
                Kontakt manuell angelegt oder importiert
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Kontakt:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ name }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      <span> {{ phone }}  </span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col
              v-if="address"
              md="4"
            >
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Adresse:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ address.street }} {{ address.houseNumber }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ address.zip }} {{ address.city }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col
              v-if="address"
              md="4"
            >
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Kaufpreis:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ formatPrice(purchasePrice) }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Kaufdatum:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ formatDate(purchaseDate, 'namedMonthWithYear') }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>
<script>
import LEAD from '../queries/Lead.gql'
import currencyMixin from '@/mixins/currency'

export default {
  mixins: [currencyMixin],

  props: {
    data: { type: Object, default: null },
    leadId: { type: String, default: '' }
  },
  computed: {
    name () {
      if (!this.lead) return
      return `${this.lead.firstname} ${this.lead.lastname}`
    },
    phone () {
      if (!this.lead) return
      return this.lead.phone
    },
    address () {
      if (!this.lead) return
      return this.lead.purchaseProperty.address
    },
    purchasePrice () {
      if (!this.lead) return
      return this.lead.purchaseProperty.purchasePrice
    },
    purchaseDate () {
      if (!this.lead) return
      return this.lead.purchaseProperty.purchaseDate
    },
    currency () {
      if (!this.lead || !this.lead.purchaseProperty.purchasePrice) return
      return this.lead.company.country === 'ch' ? 'CHF' : '€'
    }
  },
  apollo: {
    lead: {
      query: LEAD,
      variables () {
        return {
          id: this.leadId
        }
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
