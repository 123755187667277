<template>
  <v-card
    outlined
    class="mt-4 pa-4"
  >
    <h4 class="mb-1 subtitle-2">
      Löschen
    </h4>
    <div
      class="grey--text text--darken-1 caption"
    >
      Den Kontakt unwiderruflich entfernen.
    </div>

    <div class="justify-end mt-3 d-flex">
      <v-btn
        small
        @click="dialog = true"
      >
        Löschen

        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
          persistent
        >
          <v-card>
            <v-card-title
              class="pa-0"
            >
              <v-toolbar
                color="orange"
                dark
                flat
              >
                <v-toolbar-title>
                  <v-icon
                    medium
                    left
                  >
                    mdi-alert
                  </v-icon>
                  Achtung
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="dialog = false"
                  >
                    <v-icon large>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="mt-4">
              <p>Sie sind dabei, diesen Kontakt unwiderruflich zu löschen!</p>
              <p>Der Kontakt kann nicht wiederhergestellt werden.</p>
            </v-card-text>
            <v-card-actions class="flex justify-end">
              <v-btn
                @click="dialog = false"
              >
                Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteContact"
              >
                Löschen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import DELETE_LEAD from './queries/DeleteLead.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    leadId: { type: String, default: null }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    async deleteContact () {
      try {
        const { data: { response } } = await this.$apollo.mutate({
          mutation: DELETE_LEAD,
          variables: {
            id: this.leadId
          }
        })

        if (response) {
          this.dialog = false
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Kontakt gelöscht' })
          this.$router.push({ path: '/leads', replace: true })
        }
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Löschen' })
      }
    }
  }
}
</script>
