<template>
  <v-card
    v-if="landingpageTemplates && landingpages"
    outlined
  >
    <v-card-title class="pt-12 pb-2 pl-4 blue">
      <span class="white--text">
        Akquise-Booster
      </span>
    </v-card-title>
    <v-card-text
      v-if="isHoppermationActivated"
      class="pt-2"
    >
      <span class="font-weight-bold">
        Schritt 1: Thema auswählen
      </span><br>
      <p>Mit welchem Thema wollen Sie den Kontakt {{ leadName }} ansprechen?</p>
      <v-select
        ref="landingpageSlug"
        v-model.lazy="landingpageSlug"
        class="mt-2"
        outlined
        label="Themenauswahl"
        validate-on-blur
        :items="possibleLandingpageSlugs"
        item-value="slug"
        item-text="name"
        dense
        required
      />
      <span class="font-weight-bold">
        Schritt 2: E-Mail absenden
      </span><br>
      <p>
        Eine starke E-Mail geht an den Kontakt {{ leadName }}. So weiß dieser über Ihre neuen Services Bescheid. Das ist der Booster für Ihre Akquise!
      </p>
    </v-card-text>
    <v-card-text
      v-else
      class="pt-2 font-weight-bold"
    >
      Um den Akquise-Booster zu nutzen, aktivieren Sie den neuen E-Mail-Funnel.
    </v-card-text>
    <v-card-actions class="justify-end d-flex">
      <v-btn
        class="mb-3 mr-3 primary"
        small
        :disabled="!landingpageSlug"
        @click="submit"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-rocket-launch
        </v-icon>
        Akquise-Booster starten
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import invitationMixin from '@/mixins/invitation'
import BLOCKED_LANDINGPAGES_SLUGS_FOR_INVITATION from '@/modules/leads/queries/BlockedLandingpagesSlugsForInvitation.gql'
import MAIL_PROVIDER from '@/modules/leads/queries/MailProvider.gql'

export default {
  mixins: [invitationMixin],

  computed: {
    isHoppermationActivated () {
      return this.mailProvider === 'HOPPERMATION'
    },
    possibleLandingpageSlugs () {
      return this.landingpageSlugs
        .map((lpTemplate) => ({
          ...lpTemplate,
          disabled: lpTemplate.disabled || this.blockedLandingpagesSlugs.includes(lpTemplate.slug)
        }))
        .sort((a, b) => Number(a.disabled) - Number(b.disabled))
    }
  },
  apollo: {
    mailProvider: {
      query: MAIL_PROVIDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: (data) => data.company.mailProvider
    },
    blockedLandingpagesSlugs: {
      query: BLOCKED_LANDINGPAGES_SLUGS_FOR_INVITATION,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            leadId: this.lead?.id
          }
        }
      }
    }
  }
}
</script>
