<template>
  <div>
    <Leaf
      class="banner-design-elements"
      width="90px"
      style="right: 200px; top:30px; opacity: 0.7"
      stroke
      :stroke-width="1"
    />

    <Leaf
      class="banner-design-elements"
      width="40px"
      style="right: 140px; top:10px; opacity: 0.3"
      fill
    />

    <Leaf
      class="banner-design-elements"
      width="100px"
      style="right: 60px; top:80px; opacity: 0.3"
      fill
    />

    <SingleCircle
      class="banner-design-elements"
      width="50"
      style="right: 80px; top:5px; opacity: 0.5"
      stroke
      :stroke-width="3"
    />

    <SingleCircle
      class="banner-design-elements"
      width="60px"
      style="right: 40px; top:10px; opacity: 0.3"
      fill
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'

export default {
  components: {
    Leaf,
    SingleCircle
  }
}
</script>
