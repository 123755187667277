<template>
  <v-timeline-item
    v-if="conversion"
    color="leadActivity"
    icon="mdi-home-search"
    fill-dot
  >
    <v-expansion-panels
      v-if="conversion && property"
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="flex-row justify-center d-flex"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col>
              <div class="mb-2 subtitle-1">
                Hat ein Kaufgesuch über den Wohntraum-Finder abgegeben
              </div>
              <div class="grey--text">
                {{ propertyTypeLabel }} • in {{ property.location.description }} + {{ property.location.radius }}km • {{ formatPrice(property.minPrice) }} bis {{ formatPrice(property.maxPrice) }}
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Angegebener Kontakt:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ personalData.firstname }} {{ personalData.lastname }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ personalData.phone }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <TimelineListItem
                  label="Quelle"
                  :value="source"
                />
                <TimelineListItem
                  label="Wohnt derzeit"
                  :value="currentLivingSituation"
                />
                <TimelineListItem
                  label="Finanzierung"
                  :value="financing"
                />
                <TimelineListItem
                  label="Zeitrahmen"
                  :input-class="isPropertyRequestUrgent?'red--text' :'grey--text'"
                  :value="availableStartDate"
                />
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <TimelineListItem
                  label="Sucht nach"
                  :value="propertyTypeLabel"
                />
                <TimelineListItem
                  label="Lage"
                  :value="residentialArea"
                />
                <TimelineListItem label="im Suchgebiet">
                  {{ property.location.description }} + {{ property.location.radius }}km
                </TimelineListItem>
                <TimelineListItem label="Wichtig für den Kontakt">
                  <ul>
                    <li
                      v-for="surrounding of surroundings"
                      :key="surrounding"
                    >
                      {{ surrounding }}
                    </li>
                  </ul>
                </TimelineListItem>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <TimelineListItem label="Zimmer">
                  mindestens {{ property.minNumberOfRooms }}
                </TimelineListItem>
                <TimelineListItem label="Badezimmer">
                  mindestens {{ property.minNumberOfBathrooms }}
                </TimelineListItem>
                <TimelineListItem label="Stellplätze">
                  mindestens {{ property.minNumberOfParkingSpaces }}
                </TimelineListItem>
                <TimelineListItem
                  label="Favorisierter Außenbereich"
                  :value="outdoorArea"
                />
                <TimelineListItem
                  label="Barrierefreiheit"
                  :value="accessibility"
                />
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import spaceUnitMixin from '@/mixins/spaceUnit'
import { propertySubTypes, propertyTypes } from '@/lib/propertyTypes'
import {
  AccessibilityLabels,
  AvailableStartDateLabels,
  FinancingLabels,
  LivingSituationLabels,
  OutdoorAreaLabels,
  ResidentialAreaLabels,
  SurroundingsLabels
} from '@/lib/propertyRequest'
import CONVERSION from '../queries/Conversion.gql'
import { conversionSource } from '@/lib/conversionSource'
import TimelineListItem from './TimelineListItem.vue'
import AvailableStartDate from '@/lib/enums/propertyRequest/AvailableStartDate'

export default {
  components: { TimelineListItem },
  mixins: [currencyMixin, spaceUnitMixin],
  props: {
    data: { type: Object, default: null },
    leadStatus: { type: String, default: '' }
  },
  data () {
    return {
      conversion: {
        personalData: {},
        propertyRequest: {}
      }
    }
  },
  computed: {
    personalData () {
      return this.conversion?.personalData
    },
    propertyRequest () {
      return this.conversion?.propertyRequest
    },
    property () {
      return this.conversion?.propertyRequest?.property
    },
    propertyTypeLabel () {
      if (this.property?.type) {
        return `${propertyTypes[this.property.type]} ${propertySubTypes[this.property.subType] || 'flexibel'}`
      }
      return 'flexibel'
    },
    source () {
      return this.conversion?.source || conversionSource[this.conversion?.origin] || ''
    },
    accessibility () {
      return AccessibilityLabels[this.property?.accessibility]
    },
    availableStartDate () {
      return AvailableStartDateLabels[this.propertyRequest?.availableStartDate]
    },
    financing () {
      return FinancingLabels[this.propertyRequest?.financing] || 'keine Angabge'
    },
    currentLivingSituation () {
      return LivingSituationLabels[this.propertyRequest?.currentLivingSituation] || 'keine Angabge'
    },
    outdoorArea () {
      return OutdoorAreaLabels[this.property?.outdoorArea] || 'flexibel'
    },
    residentialArea () {
      return ResidentialAreaLabels[this.property?.residentialArea] || 'flexibel'
    },
    surroundings () {
      return this.property.surroundings.map((key) => SurroundingsLabels[key])
    },
    isPropertyRequestUrgent () {
      return this.propertyRequest.availableStartDate === AvailableStartDate.EARLIEST && this.leadStatus === 'new'
    }
  },
  apollo: {
    conversion: {
      query: CONVERSION,
      variables () {
        return {
          conversionId: this.data.details[this.data.type].conversionId
        }
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
