<template>
  <v-list-item>
    <v-list-item-icon class="my-2 mr-5 py-1">
      <v-icon
        v-if="channel.unsubscribedAt"
        color="red"
      >
        mdi-email-remove
      </v-icon>
      <v-icon
        v-else-if="channel.subscribedAt"
        color="success"
      >
        mdi-email-check
      </v-icon>
      <v-icon
        v-else
        color="orange"
      >
        mdi-email-lock
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content class="py-0">
      <v-list-item-title class="py-1 break-line d-flex flex-row justify-space-between align-center">
        <span v-if="channel.unsubscribedAt">Automatische E-Mails inaktiv</span>
        <span v-else-if="channel.subscribedAt">Automatische E-Mails aktiv</span>
        <span v-else>Automatische E-Mails eingeladen</span>
        <StopChannelDialog
          v-if="!channel.unsubscribedAt && !!channel.subscribedAt"
          :channel="channel"
          :lead-id="leadId"
        />
        <ResendChannelDialog
          v-if="hasResendOptInFeature && !channel.unsubscribedAt && !channel.subscribedAt"
          :channel="channel"
          :lead-id="leadId"
        />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import feature from '@/mixins/feature'
import ResendChannelDialog from './ResendChannelDialog.vue'
import StopChannelDialog from './StopChannelDialog.vue'

export default {
  components: { StopChannelDialog, ResendChannelDialog },
  mixins: [feature],
  props: {
    leadId: { type: String, required: true },
    channel: { type: Object, required: true }
  },
  computed: {
    hasResendOptInFeature () {
      return this.getFeature(this.featureNames.HOPPERMATION)?.config?.hasResendOptIn
    }
  }
}
</script>

<style scoped>
.break-line {
  white-space: normal;
}
</style>
