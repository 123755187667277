<template>
  <!-- v-if needs to be here, bc filters and column settings depend on the landingpage types  -->
  <div
    v-if="landingpages"
    class="full-size-container h-full"
  >
    <BaseHeader
      v-if="!title"
      title="Übersicht aller Kontakte"
      :style-variant="8"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>Alle Kontakte auf einen Blick. Hier können Sie filtern, Details aufrufen und exportieren.</p>
      </template>
    </BaseHeader>
    <LeadList
      :leads="preparedLeads"
      :loading="$apollo.queries.leads.loading"
      :server-items-length="leads.total"
      :items-per-page="itemsPerPage"
      :refetch-leads="refetchLeads"
      :disable-filters="isOnlyAssignedToMe"
      :disable-lead-import="!canImportLeads"
      :landingpage-types="landingpages"
      :select-items="selectLead"
      :is-lead-import-table="canImportLeads"
      :selected-items-length="selectedLeadIds.length"
      :parent-trigger-select-all-items="triggerSelectAllItems"
      :trigger-reset-acquisition-booster="triggerResetAcquisitionBooster"
      :select-all-leads-state="selectAllLeadsState"
      :title="title"
      @allItemsSelected="allItemsSelected"
      @updateInvitationParameters="updateInvitationParameters"
      @sendInvitation="sendInvitation"
      @triggeredSelectAllItems="triggeredSelectAllItems"
      @triggeredResetAcquisitionBooster="triggeredResetAcquisitionBooster"
    >
      <template v-slot:export>
        <Feature :feature-slug="featureNames.LEAD_EXPORT">
          <export
            v-if="!isOnlyAssignedToMe"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :search="search"
            :filters="filters"
          />
        </feature>
      </template>
    </LeadList>
  </div>
</template>

<script>
import LEADS from './queries/Leads.gql'
import INVITE_LEADS_TO_LANDINGPAGE from '@/modules/leads/queries/InviteLeadsToLandingpage.gql'
import LANDINGPAGES from './Landingpages.gql'
import ACQUISITION_BOOSTER_LEAD_IDS from './queries/AcquisitionBoosterLeadIds.gql'
import BaseHeader from '@/components/BaseHeader.vue'
import bus, { eventNames } from '@/lib/eventBus'
import HeaderDesign from './HeaderDesign.vue'
import { InvitationContactFilter } from './enums/Invitation'
import featureMixin from '@/mixins/feature'
import featureNames from '@/lib/featureNames'
import { Feature } from 'feature-config'

export default {
  components: {
    LeadList: () => import('./LeadList'),
    Export: () => import('./Export'),
    BaseHeader,
    HeaderDesign,
    Feature
  },
  mixins: [featureMixin],
  props: {
    isOnlyAssignedToMe: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      leads: {
        total: 0,
        entries: []
      },
      sortBy: 'lastConversionAt',
      sortDesc: true,
      page: 1,
      itemsPerPage: 20,
      search: '',
      filters: {
        currentStatus: null,
        assignedUser: null,
        valuations: false,
        returnCalls: false,
        lifeAnnuities: false,
        partialSales: false,
        leadOriginConversion: true,
        leadOriginImport: true
      },
      landingpageSlugFilter: '',

      selectAllLeadsState: 'idle',
      selectedLeadIds: [],

      permissions: {
        leadImport: {
          hasPermission: true
        }
      },
      sendingInvitation: false,

      company: {},

      featureNames,

      triggerSelectAllItems: false,
      triggerResetAcquisitionBooster: false
    }
  },
  apollo: {
    leads: {
      query: LEADS,
      variables () {
        return {
          landingpageSlug: this.landingpageSlugFilter,
          input: {
            companyId: this.$auth.user.companyId,
            onlyAssignedToMe: this.isOnlyAssignedToMe,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            search: this.search,
            filters: Object.assign({}, this.filters)
          }
        }
      },
      result () {
        this.triggerSelectAllItems = false
        setTimeout(() => {
          this.triggerSelectAllItems = true
        }, 1000)
      }
    },
    company: {
      query: LANDINGPAGES,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },
  computed: {
    landingpages () {
      if (!this.company) return
      return this.company.landingpages
    },

    preparedLeads () {
      if (!this.leads) return
      return this.leads.entries.map(item => (
        {
          ...item,
          hasPropertyTrackerInvitation: this.propertyTrackerInvitationSent(item)
        }
      ))
    },
    canImportLeads () {
      const leadImportFeature = this.getFeature(this.featureNames.LEAD_IMPORT)
      return !(leadImportFeature && leadImportFeature.isActive)
    }
  },
  methods: {
    triggeredResetAcquisitionBooster () {
      this.triggerResetAcquisitionBooster = false
    },
    triggeredSelectAllItems () {
      this.triggerSelectAllItems = false
    },
    refetchLeads ({ sortBy = this.sortBy, sortDesc = this.sortDesc, page = this.page, itemsPerPage = this.itemsPerPage, search = this.search, filters = this.filters, landingpageSlugFilter = this.landingpageSlugFilter }) {
      // cancel pending call
      clearTimeout(this._timerId)

      this.page = page
      this.sortBy = sortBy
      this.sortDesc = sortDesc
      this.itemsPerPage = itemsPerPage
      this.filters = filters
      this.landingpageSlugFilter = landingpageSlugFilter

      // delay new call 1000ms
      this._timerId = setTimeout(() => {
        this.search = search
        this.$apollo.queries.leads.refetch()
      }, 1000)
    },

    async sendInvitation () {
      this.sendingInvitation = true

      if (this.selectedLeadIds) {
        try {
          await this.$apollo.mutate({
            mutation: INVITE_LEADS_TO_LANDINGPAGE,
            variables: {
              input: {
                leadIds: this.selectedLeadIds,
                landingpageSlug: this.landingpageSlugFilter
              }
            }
          })
          this.selectedLeadIds = []
          this.landingpageSlugFilter = ''
          this.triggerResetAcquisitionBooster = true
          await this.refetchLeads({})
          this.sendingInvitation = false
          bus.$emit(eventNames.SHOW_ANIMATION)
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Alle Einladungen versendet' })
        } catch (error) {
          this.sendingInvitation = false
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Versand der Einladungen' })
          // eslint-disable-next-line no-console
          console.log(error)
        }
      }
    },

    selectLead (items = []) {
      this.selectedLeadIds = []
      items.forEach(item => {
        if (!item.invitationBlockedReason) {
          this.selectedLeadIds.push(item.id)
        }
      })
      if (this.selectAllLeadsState === 'selected') this.selectAllLeadsState = 'idle'
    },

    async allItemsSelected (selectAll) {
      this.selectAllLeadsState = 'loading'

      if (selectAll) {
        try {
          const { data: { getAcquisitionBoosterLeadIds } } = await this.$apollo.mutate({
            mutation: ACQUISITION_BOOSTER_LEAD_IDS,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                onlyAssignedToMe: this.isOnlyAssignedToMe,
                search: this.search,
                landingpageSlug: this.landingpageSlugFilter,
                filters: Object.assign({}, this.filters)
              }
            }
          })

          this.selectedLeadIds = getAcquisitionBoosterLeadIds.leadIds
          this.selectAllLeadsState = 'selected'
        } catch (err) {
          this.selectAllLeadsState = 'idle'
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Auswählen aller Kontake' })
        }
      } else {
        this.selectedLeadIds = []
        this.selectAllLeadsState = 'idle'
      }
    },
    async updateInvitationParameters ({ contactFilter, landingpageSlug, filters = {} }) {
      if (!contactFilter || !landingpageSlug) return
      this.landingpageSlugFilter = landingpageSlug
      if (contactFilter === InvitationContactFilter.CUSTOM) {
        this.selectedLeadIds = []
      } else {
        try {
          const { data: { getAcquisitionBoosterLeadIds } } = await this.$apollo.mutate({
            mutation: ACQUISITION_BOOSTER_LEAD_IDS,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                landingpageSlug,
                filters: Object.assign({}, filters)
              }
            }
          })
          this.selectedLeadIds = getAcquisitionBoosterLeadIds.leadIds
        } catch (err) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Auswählen der Kontake' })
        }
      }
    },

    propertyTrackerAllowed ({ purchaseProperty }) {
      if (
        purchaseProperty &&
        // invitation already sent
        !purchaseProperty?.landingpageAccessCode &&
        // propertyTracker already activated
        !purchaseProperty?.dossier
      ) {
        return true
      }
      return false
    },

    propertyTrackerInvitationSent ({ purchaseProperty }) {
      if (
        purchaseProperty &&
        (purchaseProperty.landingpageAccessCode || purchaseProperty.dossier)
      ) {
        return true
      }
      return false
    }
  },
  beforeUnmount () {
    clearTimeout(this._timerId)
  }
}
</script>
