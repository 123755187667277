<template>
  <div>
    <BaseHeader
      title="Kontaktdetails"
      :style-variant="16"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          Wie ist jemand auf Sie aufmerksam geworden? Wofür interessiert sich der Kontakt?
          Hier können Sie kontaktieren, kommentieren, zuweisen und exportieren.
        </p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>
    <v-row v-if="lead">
      <v-col
        cols="12"
        md="4"
      >
        <base-info
          :lead="lead"
          @lead-updated="refetchLead"
        />
        <mail-info
          v-if="!isHoppermationFeatureActive"
          :lead="lead"
          @unsubscribed="refetchLead"
        />
        <Conversions
          v-if="isHoppermationFeatureActive"
          :lead="lead"
        />

        <PropertyTrackerStatus
          class="mt-4"
          :lead="lead"
          :on-refetch="refetchLead"
        />

        <Feature
          :feature-slug="featureNames.ACQUISITION_BOOSTER"
        >
          <Invitation
            class="mt-4"
            :lead="lead"
            :on-refetch="refetchLead"
          />
        </Feature>
        <status
          :lead-id="lead.id"
          :status="lead.currentStatus"
          :assigned-user-id="lead.assignedUser && lead.assignedUser.id"
        />
        <commentary :lead-id="lead.id" />
        <Feature :feature-slug="featureNames.LEAD_EXPORT">
          <v-card
            outlined
            class="mt-4 pa-4"
          >
            <h4 class="mb-1 subtitle-2">
              Export
            </h4>
            <div
              class="grey--text text--darken-1 caption"
            >
              Zuletzt exportiert: {{ lead.exportedAt ? formatDate(lead.exportedAt) : '-' }}
            </div>
            <div class="justify-end mt-2 d-flex">
              <v-btn
                small
                color="primary"
                @click.prevent="downloadLead"
              >
                Export (.CSV)
              </v-btn>
            </div>
          </v-card>
        </Feature>
        <Feature :feature-slug="featureNames.DELETE_CONTACT">
          <DeleteContact
            :lead-id="lead.id"
          />
        </Feature>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <lead-property-map
          v-if="hasMap"
          :lead="lead"
        />
        <timeline :lead="lead" />
      </v-col>
    </v-row>
    <v-alert
      v-if="error"
      type="error"
    >
      <span v-if="error === 'NOT_FOUND'">
        Kontakt konnte nicht gefunden werden
      </span>
      <span v-else>
        Etwas ist schief gelaufen. Kontakt konnte nicht geladen werden ({{ error }}).
      </span>
    </v-alert>
  </div>
</template>

<script>
import LEAD from './queries/Lead.gql'
import BaseInfo from './BaseInfo'
import MailInfo from './MailInfo'
import Timeline from './Timeline'
import Status from './Status'
import Commentary from './Commentary'
import LeadPropertyMap from './LeadPropertyMap'
import { exportLead } from '@/lib/export'
import Invitation from './Invitation'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from './HeaderDesign.vue'
import featureMixin from '@/mixins/feature'
import Conversions from './Conversions.vue'
import PropertyTrackerStatus from './PropertyTrackerStatus'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import DeleteContact from './DeleteContact.vue'

export default {
  components: {
    BaseInfo,
    MailInfo,
    Timeline,
    Status,
    Commentary,
    LeadPropertyMap,
    Invitation,
    BaseHeader,
    HeaderDesign,
    Conversions,
    PropertyTrackerStatus,
    Feature,
    DeleteContact
  },
  mixins: [featureMixin],
  apollo: {
    lead: {
      query: LEAD,
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      pollInterval: 60000,
      error (error) {
        this.error = error.graphQLErrors[0]?.extensions?.code
      }
    }
  },
  data () {
    return {
      lead: null,
      error: null,
      featureNames
    }
  },
  computed: {
    isHoppermationFeatureActive () {
      return this.isFeatureActive(this.featureNames.HOPPERMATION)
    },
    breadcrumbItems () {
      return [
        { text: 'Übersicht aller Kontakte', to: { path: `/leads${this.$route?.hash || ''}` }, disabled: false, exact: true },
        { text: 'Details' }
      ]
    },
    hasMap () {
      const hasValuation = this.lead.valuations.length > 0
      const hasPurchaseProperty = this.lead.purchaseProperty?.address?.lat && this.lead.purchaseProperty?.address?.lng
      const hasPlotValuation = this.lead.conversions.some(conversion => conversion.type === 'PLOT_VALUATION')
      const hasPropertyRequest = this.lead.conversions.some(conversion => conversion.type === 'PROPERTY_REQUEST')

      return hasValuation || hasPurchaseProperty || hasPlotValuation || hasPropertyRequest
    }
  },
  methods: {
    async downloadLead () {
      await exportLead({ id: this.lead.id })
      await this.$apollo.queries.lead.refetch()
    },
    async refetchLead () {
      await this.$apollo.queries.lead.refetch()
    }
  }
}
</script>
