<template>
  <v-card
    v-if="lead && items.length"
    outlined
  >
    <v-timeline
      align-top
      dense
    >
      <template v-for="(item, index) in items">
        <component
          :is="component(item)"
          :key="index"
          :data="item"
          :lead-status="lead.currentStatus"
          :lead-id="lead.id"
        />
      </template>
    </v-timeline>
  </v-card>
</template>

<script>
import { sortByDate } from '@/lib/sort-functions'
import TimelineDownload from './Download'
import TimelineValuation from './Valuation'
import TimelineReturnCall from './ReturnCall'
import TimelineLifeAnnuity from './LifeAnnuity'
import TimelinePartialSale from './PartialSale'
import TimeLinePlotValuation from './PlotValuation'
import TimelineLeadStatus from './LeadStatus'
import TimelineMessage from './Message'
import TimelineCommentary from './Commentary'
import TimelineEmailActivity from './EmailActivity'
import TimelinePropertyTrackerDossier from './PropertyTrackerDossier'
import TimelinePropertyRequest from './PropertyRequest'
import TimelineLeadImported from './LeadImported'
import TimelineLeadValuation from './LeadValuation.vue'
import TimelineInAppValuation from './InAppValuation.vue'
import TimelineQuizzard from './Quizzard.vue'
import TimelineInvitation from './Invitation.vue'
import ChannelActivity from './ChannelActivity.vue'

export default {
  components: {
    TimelineDownload,
    TimelineValuation,
    TimelineReturnCall,
    TimelineLifeAnnuity,
    TimelinePartialSale,
    TimeLinePlotValuation,
    TimelineLeadStatus,
    TimelineMessage,
    TimelineEmailActivity,
    TimelineCommentary,
    TimelinePropertyTrackerDossier,
    TimelineLeadImported,
    TimelineQuizzard,
    TimelineInvitation
  },
  props: {
    lead: { type: Object, default: null }
  },
  computed: {
    items () {
      return [
        ...(this.lead.statusHistory || []),
        ...(this.lead.messages || []),
        ...(this.lead.activities || []),
        ...(this.lead.commentaries || []),
        ...((this.lead.purchaseProperty && this.lead.purchaseProperty.dossier && [this.lead.purchaseProperty.dossier]) || [])
      ].sort(sortByDate('createdAt', 'desc'))
    }
  },
  methods: {
    component ({ __typename, type, details }) {
      return {
        StatusHistory: () => TimelineLeadStatus,
        Message: () => TimelineMessage,
        Commentary: () => TimelineCommentary,
        PriceHubbleDossier: () => TimelinePropertyTrackerDossier,
        LeadActivity: () => ({
          CONVERSION: () => ({
            VALUATION: TimelineLeadValuation,
            RETURN_CALL: TimelineReturnCall,
            DOWNLOAD: TimelineDownload,
            LIFE_ANNUITY: TimelineLifeAnnuity,
            PARTIAL_SALE: TimelinePartialSale,
            PROPERTY_REQUEST: TimelinePropertyRequest,
            PLOT_VALUATION: TimeLinePlotValuation
          }[details[type]?.conversionType]),
          FUNNEL_UNSUBSCRIBED: () => ChannelActivity,
          CHANNEL_UNSUBSCRIBED: () => ChannelActivity,
          CHANNEL_SUBSCRIBED: () => ChannelActivity,
          CHANNEL_RESUBSCRIBED: () => ChannelActivity,
          EMAIL_SENT: () => TimelineEmailActivity,
          EMAIL_OPENED: () => TimelineEmailActivity,
          EMAIL_SPAM_COMPLAINT: () => TimelineEmailActivity,
          EMAIL_BOUNCED: () => TimelineEmailActivity,
          LEAD_IMPORTED: () => TimelineLeadImported,
          INVITATION: () => TimelineInvitation,
          IN_APP_VALUATION: () => TimelineInAppValuation,
          PRE_CONVERSION: () => ({
            QUIZ: TimelineQuizzard
          }[details[type]?.preConversionType])
        }[type]())
      }[__typename]()
    }
  }
}
</script>
