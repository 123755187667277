<!-- eslint-disable vue/no-v-html -->
<template>
  <v-timeline-item
    v-if="ACTIVITIES[data.type]"
    :color="color"
    :icon="activity.icon"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel
        :disabled="!data?.details?.email?.messageId"
        @change="getMessage"
      >
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col md="8">
              <div class="subtitle-1 black--text">
                {{ activity.title }}
              </div>
              <div class="grey--text text--small">
                <span v-if="data.details.email.type">{{ data.details.email.type }}</span>
                <span v-if="initiator"> von {{ initiator }}</span>
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text text--small"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown, 'pr-8': !data?.details?.email?.messageId }"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="message">
            <v-col>
              <div>Der Lead hat folgenden Text per E-Mail erhalten:</div>
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Betreff:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2 py-2 px-4 rounded lighten-4 grey">
                      {{ message.subject }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Text:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      <div
                        class="text-caption email-text py-2 px-4 rounded lighten-4 grey"
                        v-html="formatText(message.textBody)"
                      />
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import MESSAGE from '../queries/Message.gql'

const COLORS = {
  SYSTEM: 'systemActivity',
  MAKLER: 'userActivity',
  LEAD: 'leadActivity'
}

const ACTIVITIES = {
  EMAIL_SENT: () => ({
    icon: 'mdi-email-outline',
    title: 'E-Mail gesendet',
    type: 'SYSTEM'
  }),
  EMAIL_OPENED: () => ({
    icon: 'mdi-email-open',
    title: 'E-Mail geöffnet',
    type: 'LEAD'
  }),
  EMAIL_BOUNCED: () => ({
    icon: 'mdi-email-off-outline',
    title: 'E-Mail nicht zugestellt',
    type: 'SYSTEM'
  }),
  EMAIL_SPAM_COMPLAINT: () => ({
    icon: 'mdi-email-off-outline',
    title: 'E-Mail wurde als Spam markiert',
    type: 'LEAD'
  })
}

export default {
  mixins: [brandingMixin],
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      message: null
    }
  },
  computed: {
    activity () {
      return ACTIVITIES[this.data.type](this.data.details)
    },
    color () {
      return this.data.user ? COLORS.MAKLER : COLORS[this.activity.type]
    },
    initiator () {
      return {
        MAKLER: this.data.user
          ? `${this.data.user.firstname} ${this.data.user.lastname}`
          : 'Mitarbeiter',
        BACKOFFICE_USER: `${this.readablePlatformName} Mitarbeiter`
      }[this.data.initiator]
    }
  },
  created () {
    this.ACTIVITIES = ACTIVITIES
  },
  methods: {
    formatText (text) {
      return text.replace(
        /\n/ig, '<br/>').replace(/\[LINK\]/ig,
        '<div class="tooltip">[LINK]<span class="tooltiptext">Dieser Link wurde in der Vorschau ausgeblendet.</span></div>'
      )
    },
    async getMessage () {
      if (this.data.details.email.messageId && !this.message) {
        const { data: { message } } = await this.$apollo.query({
          query: MESSAGE,
          variables: { id: this.data.details.email.messageId }
        })
        this.message = message
      }
    }
  }
}
</script>

<style>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}

.email-text .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.email-text .tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.5s;
}

.email-text .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.email-text .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 0.7;
}
</style>
