<template>
  <v-card
    v-if="lead"
    class="mt-4 pa-4"
    outlined
  >
    <h4 class="mb-1 subtitle-2">
      Automatische E-Mails
    </h4>

    <v-chip
      v-for="subscription in lead.subscriptions"
      :key="subscription.id"
      class="mt-2 mr-2"
    >
      <v-icon
        v-if="subscription.unsubscribedAt"
        color="red"
        left
      >
        mdi-stop-circle
      </v-icon>
      <v-icon
        v-else-if="lead.confirmedAt"
        color="success"
        left
      >
        mdi-check-circle
      </v-icon>
      <v-icon
        v-else
        color="orange"
        left
      >
        mdi-clock
      </v-icon>
      {{ subscription.campaign.name }}
    </v-chip>
    <p v-if="!hasSubscriptionsOrChannel">
      Dieser Kontakt erhält keine automatischen E-Mails
    </p>
    <div class="justify-end mt-6 d-flex">
      <v-btn
        small
        color="primary"
        :disabled="!hasSubscriptionsOrChannel"
        @click.prevent="unsubscribe"
      >
        Alle E-Mail-Funnels stoppen
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import STOP_ALL_CAMPAIGNS_FOR_LEAD from './queries/StopAllCampaignsForLead.gql'

export default {
  props: {
    lead: { type: Object, default: null }
  },
  computed: {
    hasSubscriptionsOrChannel () {
      return Boolean(
        this.lead.subscriptions.filter(({ subscribedAt, unsubscribedAt }) => subscribedAt && !unsubscribedAt).length
      )
    }
  },

  methods: {
    async unsubscribe () {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: STOP_ALL_CAMPAIGNS_FOR_LEAD,
          variables: {
            input: {
              leadId: this.lead.id
            }
          }
        })

        this.$emit('unsubscribed')

        if (data.unsubscribeLead) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Funnels wurden gestoppt' })
        } else {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Stoppen der Funnels' })
        }
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Stoppen der Funnels' })
      }
    }
  }
}
</script>
