<template>
  <v-list-item class="px-0">
    <v-list-item-content>
      <v-list-item-title>{{ label }}:</v-list-item-title>
      <div
        :class="[
          inputClass,
          'text--darken-3 body-2',
          {'grey--text':inputClass.indexOf('text') === -1}
        ]"
      >
        {{ value }}
        <slot />
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    inputClass: { type: String, default: '' },
    label: { type: String, required: true },
    // eslint-disable-next-line vue/require-prop-types
    value: { default: '' }
  }
}
</script>
