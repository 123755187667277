<template>
  <v-container class="layout-container pb-8">
    <lead />
  </v-container>
</template>

<script>
import Lead from '@/modules/leads/Lead'

export default {
  components: {
    Lead
  }
}
</script>
