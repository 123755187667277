<template>
  <v-timeline-item
    v-if="showValuation"
    :color="color"
    icon="mdi-home"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="justify-center d-flex"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col md="8">
              <div class="mb-2 subtitle-1">
                {{ title }}
              </div>
              <div
                v-if="isCompleted"
                class="grey--text"
              >
                {{ hasResults ? `${formatPrice(valuation.results.property.value, valuation.results.currency)} •` : '' }}
                {{ valuation.inputs.address.street }} {{ valuation.inputs.address.houseNumber }} •
                {{ valuation.inputs.address.zip }} {{ valuation.inputs.address.city }}
              </div>
              <div
                v-else
                class="grey--text"
              >
                {{ 'Der Wert wird aktuell noch berechnet ...' }}
                <v-progress-circular
                  v-if="!isCompleted"
                  :size="15"
                  :width="2"
                  indeterminate
                  color="primary"
                  class="ml-2 mr-2"
                  small
                />
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(valuation.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col md="4">
              <v-list dense>
                <v-list-item
                  v-if="user"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Durchgeführt von:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ user.firstname }} {{ user.lastname }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="leadData"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ isCreatedByAgent ? "Zugewiesener" : "Angegebener" }} Kontakt:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ leadData.firstname }} {{ leadData.lastname }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ leadData.formattedPhone }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="hasResults"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Geschätzter Wert:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ isCompleted ? formatPrice(valuation.results.property.value, valuation.results.currency) : "Wird berechnet ..." }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!isCreatedByAgent"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Grund:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      <span v-if="reason"> {{ reason }} </span>
                      <span v-else>Nicht angegeben</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!isCreatedByAgent"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Quelle:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      <span v-if="valuationSource">{{ valuationSource }}</span>
                      <span v-else>Nicht vorhanden</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="valuation.provider === 'PRICE_HUBBLE'"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Aussagekraft des Ergebnisses:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ confidence }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="hasResults"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>PDF Vorschau:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      <a
                        v-if="isCompletedPDF"
                        :href="previewUri"
                        target="_blank"
                        rel="noopener noreferrer"
                      >Öffnen</a>
                      <div v-else>
                        <span class="mr-1">Wird generiert</span>
                        <v-progress-circular
                          :size="15"
                          :width="1"
                          indeterminate
                          color="disabled"
                        />
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Adresse:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.address.street }} {{ valuation.inputs.address.houseNumber }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.address.zip }} {{ valuation.inputs.address.city }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      Objektart:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ propertyTypeLabel }}
                      <span v-if="propertySubTypeLabel">
                        ({{ propertySubTypeLabel }})
                      </span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.condition"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Zustand:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ condition }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.quality"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Qualität:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ quality }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Baujahr:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.buildingYear }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.type === PropertyType.MULTI_FAMILY_HOUSE"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Jährliche Mieteinnahmen:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ isCompleted ? formatPrice(valuation.inputs.annualRentIncome, valuation.results?.currency) : "Wird formatiert ..." }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.type === PropertyType.MULTI_FAMILY_HOUSE"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Einheiten:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.numberOfUnits }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-else
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Zimmer:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.numberOfRooms }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>Wohnfläche:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.livingArea }} {{ formatSpaceUnit() }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.type === PropertyType.HOUSE"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Grundstücksfläche:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.landArea }} {{ formatSpaceUnit() }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.type === PropertyType.APARTMENT"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Garten-/Balkonfläche:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.balconyArea }} {{ formatSpaceUnit() }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <div class="justify-end d-flex">
            <v-btn
              small
              outlined
              color="primary"
              :disabled="!isCompleted"
              @click.prevent="downloadValuation"
            >
              Export (.xml)
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import spaceUnitMixin from '@/mixins/spaceUnit'

import { exportValuation } from '@/lib/export'
import { propertyTypes, propertySubTypes } from '@/lib/propertyTypes'
import { status as ValuationStatus } from '@/lib/valuation'
import { status as ValuationPDFStatus } from '@/lib/valuationPdfStatus'

import { conversionSource } from '@/lib/conversionSource'

import PropertyType from '@/lib/enums/valuation/PropertyType'
import Condition from '@/lib/enums/valuation/Condition'
import Quality from '@/lib/enums/valuation/Quality'
import Reason from '@/lib/enums/valuation/Reason'
import Confidence from '@/lib/enums/valuation/Confidence'

const CONDITION = [
  { value: Condition.RENOVATION_NEEDED, text: 'Renovierungsbedürftig' },
  { value: Condition.WELL_MAINTAINED, text: 'Gut erhalten' },
  { value: Condition.NEW_OR_RECENTLY_RENOVATED, text: 'Neu oder kürzlich renoviert' }
]

const QUALITY = [
  { value: Quality.SIMPLE, text: 'Einfach' },
  { value: Quality.NORMAL, text: 'Standard' },
  { value: Quality.HIGH, text: 'Hoch' },
  { value: Quality.LUXURY, text: 'Luxuriös' }
]

export const REASONS = [
  { value: '', text: 'Keine Angabe.' },
  { value: Reason.PLANNING_TO_SELL, text: 'Ich plane den Verkauf dieser Immobilie.' },
  { value: Reason.PRICE_TREND, text: 'Ich möchte wissen, ob ich vom Preisanstieg profitiert habe.' },
  { value: Reason.NEED_PROPERTY_BEFORE_SELLING, text: 'Ich würde verkaufen, wenn ich eine passende Immobilie finde.' },
  { value: Reason.HERITAGE, text: 'Ich habe eine Immobilie geerbt und möchte den Marktpreis erfahren.' },
  { value: Reason.BUYING_INTEREST, text: 'Ich interessiere mich für eine Immobilie und möchte den Preis überprüfen.' }
]

const CONFIDENCE = [
  { value: Confidence.LOW, text: 'Ok' },
  { value: Confidence.MEDIUM, text: 'Gut' },
  { value: Confidence.HIGH, text: 'Sehr gut' }
]

export default {
  mixins: [currencyMixin, spaceUnitMixin],
  props: {
    valuation: { type: Object, default: null },
    user: { type: Object, default: null },
    origin: { type: String, default: '' },
    source: { type: String, default: '' },
    leadData: { type: Object, default: null }
  },
  data () {
    return {
      PropertyType
    }
  },
  computed: {
    hasResults () {
      return this.valuation.provider !== 'MANUAL'
    },
    isCreatedByAgent () {
      return this.valuation.createdByAgent
    },
    color () {
      return this.isCreatedByAgent ? 'userActivity' : 'leadActivity'
    },
    showValuation () {
      return this.valuation && this.isCompleted && (this.valuation.userId ? this.user : true)
    },
    propertyTypeLabel () {
      return propertyTypes[this.valuation.inputs.type]
    },
    propertySubTypeLabel () {
      return propertySubTypes[this.valuation.inputs.subtype]
    },
    title () {
      if (this.isCreatedByAgent) return 'Hat eine Bewertung zugewiesen bekommen'
      if (this.hasResults) return `Hat eine Bewertung für ${this.readablePropertyType()} durchgeführt`

      return `Hat eine Bewertung für ${this.readablePropertyType()} angefragt`
    },
    condition () {
      return CONDITION.find(obj => { return obj.value === this.valuation.inputs.condition }).text
    },
    quality () {
      return QUALITY.find(obj => { return obj.value === this.valuation.inputs.quality }).text
    },
    reason () {
      return REASONS.find(obj => { return obj.value === this.valuation.reason })?.text || ''
    },
    previewUri () {
      return this.valuation.pdf.file?.cdnUrl
    },
    confidence () {
      return CONFIDENCE.find(obj => obj.value === this.valuation.results?.property.valueConfidence)?.text
    },
    valuationSource () {
      return this.source || conversionSource[this.origin] || ''
    },
    isCompleted () {
      return this.valuation.status === ValuationStatus.COMPLETED
    },
    isCompletedPDF () {
      return this.valuation.pdf.status === ValuationPDFStatus.COMPLETED
    }
  },
  methods: {
    async downloadValuation () {
      await exportValuation({ id: this.valuation.id })
    },
    readablePropertyType () {
      switch (this.valuation.inputs.type) {
        case PropertyType.HOUSE:
          return 'ein Haus'
        case PropertyType.APARTMENT:
          return 'eine Wohnung'
        case PropertyType.MULTI_FAMILY_HOUSE:
          return 'ein Mehrfamilienhaus'
        default:
          return 'eine Immobilie'
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
