<template>
  <v-card
    outlined
    class="mb-4"
  >
    <div
      ref="map"
      style="height:348px"
    />

    <MapLegend
      v-if="map"
      :map="map"
      :markers="markers"
      @apply-filter="applyFilterToMap"
    />
  </v-card>
</template>

<script>
import mapMixin from '@/mixins/map'
import MapLegend from '@/modules/map/MapLegend'

export default {
  components: { MapLegend },
  mixins: [mapMixin],
  props: {
    lead: { type: Object, default: null }
  },
  async mounted () {
    this.loadGoogleMaps()
    await this.googleMapsLoaded()

    const coordinates = this.createCoordinatesArray()

    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(this.$refs.map, {
      center: { lat: coordinates[0].lat, lng: coordinates[0].lng },
      zoom: 12,
      fullscreenControl: true,
      streetViewControl: true
    })

    const newestProperty = coordinates.findLast(coordinate => coordinate.type !== 'propertyRequest')
    if (newestProperty) {
      this.enableStreetView(newestProperty.lat, newestProperty.lng)
    }

    this.populateMap(coordinates)
  },
  methods: {
    populateMap (coordinates) {
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.circles.forEach(circle => {
        circle.setMap(null)
      })
      this.markers = []
      this.circles = []

      // eslint-disable-next-line no-undef
      var bounds = new google.maps.LatLngBounds()
      this.addMarkersToMap(coordinates, bounds)
      this.addRadiusToMap(bounds)
      if (this.markers.length > 1 || this.markers.some(m => m.radius)) {
        this.map.fitBounds(bounds)
      }
    },
    createCoordinatesArray () {
      // Property Tracker
      const coordinates = []
      if (this.lead.purchaseProperty?.address?.lat && this.lead.purchaseProperty?.address?.lng) {
        coordinates.push({ lat: this.lead.purchaseProperty?.address?.lat, lng: this.lead.purchaseProperty?.address?.lng })
      }
      // Valuations
      this.lead.valuations.forEach(valuation => {
        const type = valuation.createdByAgent ? 'user' : this.lead.currentStatus === 'new' ? 'active' : 'inactive'
        coordinates.push({ lat: valuation.inputs.coordinates.lat, lng: valuation.inputs.coordinates.lng, type })
      })

      // Conversion without valuation but has location
      this.lead.conversions.forEach(({ type, propertyRequest }) => {
        if (type === 'PROPERTY_REQUEST' && propertyRequest && propertyRequest.property && propertyRequest.property.location.coordinates.lat && propertyRequest.property.location.coordinates.lng) {
          coordinates.push({ lat: propertyRequest.property.location.coordinates.lat, lng: propertyRequest.property.location.coordinates.lng, type: 'propertyRequest', radius: propertyRequest.property.location.radius })
        }
      })
      return coordinates
    },
    async enableStreetView (lat, lng) {
      const { available, streetViewPanoramaData } = await this.getStreetViewData({ lat, lng })
      if (available) {
        this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
          this.createButtonForMap(
            'Street View',
            'map-button',
            () => this.createStreetViewPanorama({
              streetViewPanoramaData,
              lat,
              lng,
              map: this.$refs.map
            })
          )
        )
      }
    },
    addMarkersToMap (coordinates, bounds) {
      coordinates.forEach(({ lat, lng, type = 'inactive', radius }) => {
        const marker = this.createMarker({ type, address: { lat, lng }, radius })
        this.markers.push(marker)
        bounds.extend(marker.position)
      })
    },
    addRadiusToMap (bounds) {
      this.markers.filter(marker => marker.radius).forEach(marker => {
        const circle = this.createRadius(marker)
        this.circles.push(circle)
        bounds.union(circle.getBounds(), 0)
      })
    }
  }
}
</script>
<style lang="postcss">
.map-button, .street-view-button {
  margin: 10px 0 22px 10px;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  cursor: pointer;
  margin: 10px 0 22px 0;
  text-align: center;
  color: rgb(25,25,25);
  font-size: 16px;
  line-height: 36px;
  padding: 0 5px;
}
.street-view-button {
  margin: 10px 0 22px 10px;
}
</style>
